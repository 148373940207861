import { productFruits } from 'product-fruits'
import { API } from '@/api'

export const initProductFruits = (user: API.DisplayUserProfileResponseDto) => {
  if (!process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE) return

  productFruits.init(
    process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE!,
    user.languageShortcut.toLowerCase(),
    {
      username: `${user.id}`,
      props: {
        department: user.organizationRole as string,
        role: user.role,
        language: user.languageShortcut,
        country: user.countryShortcut as string,
        currency: user.currencyShortcut as string,
        user_type: user.userType as string,
        user_id: user.id,
      },
    },
  )
}

export const updateProductFruitsUser = (
  user: API.DisplayUserProfileResponseDto,
) => {
  if (!process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE) return

  productFruits.safeExec(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).productFruits.identifyUser({
      username: `${user.id}`,
      props: {
        department: user.organizationRole as string,
        role: user.role,
        language: user.languageShortcut,
        country: user.countryShortcut as string,
        currency: user.currencyShortcut as string,
        user_type: user.userType as string,
        user_id: user.id,
      },
    })
  })
}
