import { API } from '@/api'
import { i18n } from '@/i18n'
import {
  ExternalUserForDisplay,
  InternalUserForDisplay,
} from '@/routes/users/store/types'
import store from '@/store'
import { Query, has } from '@/utils'
import {
  ExternalUsersFiltersModel,
  InternalUsersFiltersModel,
} from './UsersList/useUsersFilters'

export const getUserCountryByTitle = (
  userCountry?: string,
  countries?: API.CountryWithSubregionDto[],
) => {
  if (!countries?.length) return

  return countries.find((item) => item.title === userCountry)
}

export const getUserCountry = (
  userCountry?: string,
  countries?: API.CountryWithSubregionDto[],
) => {
  if (!countries?.length) return

  return countries.find((item) => item.country === userCountry)
}

export const getUserFunction = (
  role:
    | API.UserListRowDto['organizationRole']
    | API.ExternalUserDto['organizationRole'],
) => {
  return role ? i18n.global.t(`enums.organizationRole.${role}`) : ''
}

export const internalUserDisplayFormatter = (user: API.UserListRowDto) => {
  const userCountry = getUserCountryByTitle(
    user.country,
    store.state.staticData?.countriesList,
  )

  const internalUserForDisplay: InternalUserForDisplay = {
    ...user,
    subregion: userCountry?.subregion ?? '',
    country: userCountry?.country ?? '',
    language: user.language.name,
    userFunction: getUserFunction(user.organizationRole),
  }

  return internalUserForDisplay
}

export const externalUserDisplayFormatter = (user: API.ExternalUserDto) => {
  const userCountry = getUserCountry(
    user.country,
    store.state.staticData?.countriesList,
  )

  const externalUserForDisplay: ExternalUserForDisplay = {
    ...user,
    company: user.company?.name ?? '',
    subregion: userCountry?.subregion ?? '',
    country: userCountry?.country ?? '',
    userFunction: getUserFunction(user.organizationRole),
  }

  return externalUserForDisplay
}

type User = InternalUserForDisplay | ExternalUserForDisplay

function matchesBaseFilters(filters: InternalUsersFiltersModel) {
  const countries = (filters.countries || []).map((c) => c)
  const matchesCountry = countries.length
    ? (user: User) => has(countries, user.country)
    : () => true

  const functions = filters.userFunctions || []
  const matchesFunction = functions.length
    ? (user: User) => has(functions, user.organizationRole)
    : () => true

  return (user: User) => {
    return matchesCountry(user) && matchesFunction(user)
  }
}

function matchesExternalFilters(filters: ExternalUsersFiltersModel) {
  const matchesBase = matchesBaseFilters(filters)
  const statuses = filters.scpStatuses || []
  const matchesStatus = statuses.length
    ? (user: ExternalUserForDisplay) => statuses.includes(user.scpStatus)
    : () => true

  return (user: ExternalUserForDisplay) => {
    return matchesBase(user) && matchesStatus(user)
  }
}

export const filterInternalUsers = (
  users: InternalUserForDisplay[] = [],
  filters: InternalUsersFiltersModel = {},
) => {
  const matchesQuery = Query(filters.searchValue)
  const matchesFilters = matchesBaseFilters(filters)

  return users.filter((user) => {
    return (
      matchesFilters(user) &&
      (matchesQuery(user.username) ||
        matchesQuery(user.country) ||
        matchesQuery(user.userFunction) ||
        matchesQuery(user.email))
    )
  })
}

export const filterExternalUsers = (
  users: ExternalUserForDisplay[] = [],
  filters: ExternalUsersFiltersModel = {},
) => {
  const matchesQuery = Query(filters.searchValue)
  const matchesFilters = matchesExternalFilters(filters)

  return users.filter((user) => {
    return (
      matchesFilters(user) &&
      (matchesQuery(user.username) ||
        matchesQuery(user.country) ||
        matchesQuery(user.userFunction) ||
        matchesQuery(user.email) ||
        matchesQuery(user.company))
    )
  })
}
