import { ActionContext, ActionTree } from 'vuex'
import {
  AppState,
  ChangeOriginScpCountryPayload,
  EditUserLanguagePalyoad,
  EmailHistoryType,
  FinalizeOnboardingPayload,
  FinalizeScpOnboardingPayload,
  FinderFilterPayload,
  ModalVisibility,
  PreviousRouteType,
  SearchedItem,
  urlEncodeFilters,
} from './types'

import { API } from '@/api'
import { productListRoute } from '@/components/Breadcrumbs/breadcrumbs.config'
import {
  isFirebaseConsentsGranted,
  isSalesChannelPartner,
} from '@/helpers/getters'
import { i18n } from '@/i18n'
import router from '@/routes'
import { getFilterKeysByName } from '@/routes/finder/SearchResults/finderFilterTypeMaper.service'
import { ROUTER_CONFIG } from '@/routes/router.config'
import {
  USED_FILTER_CONTENT,
  logEvent,
  setUserDataForAnalytics,
} from '@/services/analytics.service'
import {
  apiClient,
  errorHandler,
  goToLoginPage,
  handleLoginFromToken,
  loginErrorHandler,
} from '@/services/data.service'
import { uniqueEmailHistory } from '@/services/shareDialog.service'
import {
  initUserTracking,
  startUserTracking,
} from '@/services/userTracking.service'
import {
  initProductFruits,
  updateProductFruitsUser,
} from '@/services/product-fruits.service'
import { getDynamicStore } from '@/store'
import { FinderFilterType, UserSortingType } from '@/store/types'
import { AnalyticEvent } from '@/types/analyticEvents'
import { noop } from '@/utils'
import {
  getCookie,
  removeCookie,
  saveCookie,
} from '@/services/auth-token.service'
import { Size } from '@/types'

const prepareUserForSave = (
  user: API.DisplayUserProfileResponseDto,
): API.BaseUserProfileDto => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { country, currency, username, ...rest } = user

  return rest
}

const prepareScpUserForSave = (
  user: API.DisplayUserProfileResponseDto,
): API.EditScpUserProfileRequestDto => {
  if (!user.countryId) {
    throw Error('Missing country')
  }

  return {
    countryId: user.countryId,
    languageShortcut: user.languageShortcut,
  }
}

const isOnboardingRequired = (
  user: API.DisplayUserProfileResponseDto,
  isScp = false,
) => {
  const { countryId } = user
  if (isScp) {
    return countryId == null
  }

  const { currencyId, organizationRole } = user

  return [countryId, currencyId, organizationRole].some(
    (value) => value == null,
  )
}

const handleOnboardingIfNeeded = (state: AppState) => {
  const user = state.user
  if (!user) {
    return
  }
  const routerDestination = () => {
    if (!isSalesChannelPartner(state)) {
      return isOnboardingRequired(user)
        ? ROUTER_CONFIG.onboarding.name
        : undefined
    }
    if (!user.countryId) {
      return ROUTER_CONFIG.scpRegistration.name
    } else if (user.scpStatus === 'PENDING') {
      return ROUTER_CONFIG.scpRegistrationWaitingRoom.name
    } else if (user.scpStatus === 'REJECTED') {
      return ROUTER_CONFIG.scpRegistrationRejected.name
    } else if (user.scpStatus === 'ONBOARDING_REQUIRED') {
      return ROUTER_CONFIG.scpOnboarding.name
    }
  }
  const name = routerDestination()
  if (name) {
    router.push({ name })
  }
}

const goToFinder = () => router.push({ name: ROUTER_CONFIG.finder.name })

const onEditUserProfile = (
  user: API.DisplayUserProfileResponseDto,
  payload: API.BaseUserProfileDto,
) => {
  const userData: API.BaseUserProfileDto = {
    ...prepareUserForSave(user),
    ...payload,
  }

  return apiClient.user.editUserProfileUsingPut(userData)
}

const onEditScpUserProfile = (
  user: API.DisplayUserProfileResponseDto,
  payload: API.BaseUserProfileDto,
) => {
  const userData: API.EditScpUserProfileRequestDto = {
    ...prepareScpUserForSave(user),
    ...payload,
  }

  return apiClient.user.editScpUserProfileUsingPut(userData)
}

type LanguageTypes = 'en' | 'de' | 'es' | 'fr' | 'it' | 'ja' | 'pt'

const updateLanguage = (userProfileDto: API.DisplayUserProfileResponseDto) => {
  const updatedLang =
    userProfileDto?.languageShortcut?.toLowerCase() as LanguageTypes
  const currentLang = i18n.global.locale.value

  const isLanguageAvailable = () =>
    i18n.global.availableLocales.some((shortcut) => shortcut === updatedLang)

  if (updatedLang && isLanguageAvailable() && currentLang !== updatedLang) {
    i18n.global.locale.value = updatedLang
  }
}

const calculateLoadTime = (startTime: number, endTime: number) => {
  const loadTime = endTime - startTime
  const loadTimeRounded = Math.round(loadTime * 100) / 100

  return loadTimeRounded
}

const updateOnboardingNeeded = (
  ctx: Pick<ActionContext<AppState, AppState>, 'commit' | 'state'>,
  user: API.DisplayUserProfileResponseDto | undefined,
) => {
  if (!user) {
    return
  }

  const isScp = isSalesChannelPartner(ctx.state)

  ctx.commit('updateOnboardingNeeded', isOnboardingRequired(user, isScp))
}

export const actions: ActionTree<AppState, AppState> = {
  async fetchUserProfile({ commit, state }) {
    try {
      const { data } = await apiClient.user.getUserProfileUsingGet()
      commit('updateUser', data)
      updateOnboardingNeeded({ commit, state }, data)

      const isScp = isSalesChannelPartner(state)
      if (isOnboardingRequired(data, isScp)) return

      updateProductFruitsUser(data)
    } catch (e) {
      await errorHandler(e)
    }
  },

  async fetchUserProfileAndCountries({ dispatch }) {
    try {
      await dispatch('fetchUserProfile')
      await dispatch('staticData/fetchCountriesList', null, {
        root: true,
      })
    } catch (e) {
      await errorHandler(e)
    }
  },

  async fetchInitData({ commit, dispatch, state }) {
    commit('updateUser', undefined)
    commit('updateIsLoading', true)
    const isWithoutError = await dispatch('login')

    if (!isWithoutError) {
      commit('updateIsLoading', false)

      return
    }
    try {
      const { data } = await apiClient.user.getUserProfileUsingGet()
      commit('updateUser', data)
      updateOnboardingNeeded({ commit, state }, data)
      updateLanguage(data)
      commit('updateIsLoading', false)
      handleOnboardingIfNeeded(state)
      await initUserTracking()
      if (isFirebaseConsentsGranted(state)) {
        await startUserTracking(data)
        await setUserDataForAnalytics(data)
      }
      const isScp = isSalesChannelPartner(state)
      if (
        !isOnboardingRequired(data, isScp) &&
        (!isScp || data.scpStatus === 'ACCEPTED')
      ) {
        initProductFruits(data)
      }
    } catch (e) {
      errorHandler(e)
      commit('updateIsLoading', false)

      return
    }

    const staticDataAction = isSalesChannelPartner(state)
      ? 'staticData/fetchScpStaticData'
      : 'staticData/fetchStaticData'

    await Promise.all([
      dispatch(staticDataAction, null, { root: true }),
      dispatch('fetchKeyUserPermissions'),
      dispatch('getCareAreaCounters'),
      dispatch('notifications/fetchUnreadCounter'),
    ])

    dispatch('notifications/initNotifications', null, { root: true })
  },

  async login() {
    try {
      const loginResponse = await handleLoginFromToken(location.hash)
      const store = await getDynamicStore()
      const redirectPath = store.state.persisted?.redirectFromPath

      if (loginResponse) {
        // Check if third-party cookies are enabled
        let hasCookiesEnabled = false
        const authToken = getCookie('authorization')

        if (authToken && authToken !== null && authToken !== undefined) {
          hasCookiesEnabled = true
        }

        // Save cookies if third-party cookies are disabled/enabled
        if (loginResponse.data && !hasCookiesEnabled) {
          saveCookie('authorization_', loginResponse.data.accessToken)
          saveCookie('jwt_', loginResponse.data.jwt)
          saveCookie('useDisabledCookieMethod_', true)
        } else {
          saveCookie('useDisabledCookieMethod_', false)
        }

        // Redirect
        if (redirectPath) {
          router.push({ path: redirectPath.path })

          store.dispatch('setRedirectFromPath', undefined)
        } else {
          goToFinder()
        }
      }
    } catch (e) {
      loginErrorHandler(e)

      return false
    }

    return true
  },

  async logout({ commit }) {
    try {
      commit('updateIsLoading', true)

      let logout

      // Check if we use disabled cookie method
      const useDisabledCookieMethod =
        getCookie('useDisabledCookieMethod_') ?? false
      const isBlockedAccount = getCookie('isBlockedAccount_') ?? false

      const useDisabledCookieMethodStatus =
        getCookie('useDisabledCookieMethod_') ?? undefined
      const authTokenStatus = getCookie('authorization') ?? undefined
      const authTokenThirdPartyStatus = getCookie('authorization_') ?? undefined

      // Redirect to the login page when auth cookies are not available (deleted earlier) - example: fix when user click 'logout button' in 2+ cards
      if (
        !isBlockedAccount &&
        useDisabledCookieMethodStatus === undefined &&
        (authTokenStatus === undefined ||
          authTokenThirdPartyStatus === undefined)
      ) {
        goToLoginPage()
      } else if (useDisabledCookieMethod == 'true' || isBlockedAccount) {
        // Logout when third cookies are disabled or is blocked account
        const jwt = getCookie('jwt_') ?? undefined

        logout = await apiClient.logout
          .logoutUsingGet(
            { jwtToken: jwt, mobile: false },
            {
              headers: {
                referer: window.location.origin + '/',
              },
            },
          )
          .catch(noop)
      } else {
        removeCookie('useDisabledCookieMethod_')
        removeCookie('isBlockedAccount_')

        logout = await apiClient.logout
          .logoutUsingGet(
            { mobile: false },
            {
              headers: {
                referer: window.location.origin + '/',
              },
            },
          )
          .catch(noop)
      }

      if (logout && logout.data?.value) {
        removeCookie('authorization_')
        removeCookie('jwt_')
        removeCookie('useDisabledCookieMethod_')
        removeCookie('isBlockedAccount_')
        commit('updateUser', undefined)

        window.location.replace(logout.data.value)
      }
    } catch (e) {
      errorHandler(e)

      return
    }
  },

  async updateUser(
    { commit, dispatch, state },
    payload: API.BaseUserProfileDto,
  ) {
    if (!state.user) {
      return
    }

    try {
      const { data } = isSalesChannelPartner(state)
        ? await onEditScpUserProfile(state.user, payload)
        : await onEditUserProfile(state.user, payload)
      await dispatch('fetchUserProfileAndCountries')
      commit('updateUser', data)
      updateOnboardingNeeded({ commit, state }, data)
      updateLanguage(data)

      if (
        'hideWithoutMarketClearance' in payload ||
        'clearanceCheck' in payload
      ) {
        await dispatch('getCareAreaCounters')
      }
    } catch (e) {
      await errorHandler(e)
    }
  },

  async fetchKeyUserPermissions({ commit, state }) {
    if (state.user?.role !== 'KEY_USER') {
      return // Fetch data only for Key Users
    }
    try {
      const keyUserPermissions = (
        await apiClient.user.getSubregionsWithPermissionUsingGet()
      ).data.content
      commit('updateKeyUserPermissions', keyUserPermissions)
    } catch (e) {
      errorHandler(e)
    }
  },

  async finalizeOnboarding(
    { dispatch, commit, state },
    payload: FinalizeOnboardingPayload,
  ) {
    if (!state.user) {
      return
    }
    const onSuccess = (user: API.DisplayUserProfileResponseDto) => {
      commit('updateUser', user)
      commit('updateOnboardingNeeded', isOnboardingRequired(user))
      updateLanguage(user)

      dispatch('getCareAreaCounters')
      goToFinder()
    }

    try {
      const { data } = await apiClient.user.editUserProfileUsingPut({
        ...prepareUserForSave(state.user),
        ...payload,
      })
      onSuccess(data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async setUserLanguage({ commit, state }, payload: EditUserLanguagePalyoad) {
    if (!state.user) {
      return
    }

    try {
      const { data } = await apiClient.user.editLanguageUsingPut(payload)

      commit('updateUser', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async finalizeScpOnboarding(
    { dispatch, commit, state },
    payload: FinalizeScpOnboardingPayload,
  ) {
    if (!state.user) {
      return
    }
    const onSuccess = (user: API.DisplayUserProfileResponseDto) => {
      commit('updateUser', user)
      commit('updateOnboardingNeeded', isOnboardingRequired(user))
      updateLanguage(user)
      dispatch('getCareAreaCounters')
      goToFinder()
    }

    try {
      const { data } = await apiClient.user.editScpUserProfileUsingPut({
        ...payload,
      })
      onSuccess(data)
    } catch (e) {
      errorHandler(e)

      throw e
    }
  },

  changeModalVisibility({ commit }, payload: Partial<ModalVisibility>) {
    commit('updateModalVisibility', payload)
  },

  async manageProductListCounterTimestamp({ commit, state }) {
    const timestamp = new Date().getTime()
    const counterEventTimestamp = state.persisted?.productListCounterTimestamp
    const milisecondsInDay = 60 * 60 * 24 * 1000
    //send analytic event only once per 24 hours
    if (
      !counterEventTimestamp ||
      timestamp - counterEventTimestamp > milisecondsInDay
    ) {
      commit('updatePersisted', { productListCounterTimestamp: timestamp })
    }
  },

  setIsAutocompleteDataLoading({ commit }, payload: boolean) {
    commit('updateIsAutocompleteDataLoading', payload)
  },

  async getAutocompleteProductsList({ commit, dispatch }, searchValue: string) {
    dispatch('setIsAutocompleteDataLoading', true)
    const loadStartTime = performance.now()

    try {
      const { data } = await apiClient.v3.findProductsUsingGet(
        {
          search: searchValue,
        },
        {
          headers: {
            accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
          },
        },
      )
      const loadEndTime = performance.now()
      const loadTime = calculateLoadTime(loadStartTime, loadEndTime)
      commit('updateSearchAutocomplete', { searchValue, data, loadTime })
    } catch (e) {
      errorHandler(e)
    }
    dispatch('setIsAutocompleteDataLoading', false)
  },

  async saveSearchHistoryPersistedItem(
    { commit, state },
    payload: SearchedItem,
  ) {
    try {
      const searchState = state.persisted?.searchHistory || []
      const baseSearchState = searchState
        ?.filter((item) => item.title !== payload.title)
        .slice(0, 4)
      const searchHistory = [payload, ...baseSearchState]
      commit('updatePersisted', { searchHistory: searchHistory })
    } catch (e) {
      errorHandler(e)
    }
  },

  async saveEmailHistoryPersistedItem(
    { commit, state },
    payload: EmailHistoryType,
  ) {
    try {
      const tempEmailHistoryState = payload.isScpHistory
        ? state.persisted?.scpEmailHistory
        : state.persisted?.emailHistory
      const emailHistoryState = tempEmailHistoryState || []
      const uniqueEmails = uniqueEmailHistory([
        ...payload.users,
        ...emailHistoryState,
      ])

      const dataToUpdate = payload.isScpHistory
        ? { scpEmailHistory: uniqueEmails }
        : { emailHistory: uniqueEmails }
      commit('updatePersisted', dataToUpdate)
    } catch (e) {
      errorHandler(e)
    }
  },

  async clearHistory({ commit }) {
    try {
      commit('updatePersisted', { searchHistory: [] })
    } catch (e) {
      errorHandler(e)
    }
  },

  async clearEmailHistory({ commit }, isScpHistory: boolean) {
    try {
      const payload = isScpHistory
        ? { scpEmailHistory: [] }
        : { emailHistory: [] }
      commit('updatePersisted', payload)
    } catch (e) {
      errorHandler(e)
    }
  },

  async savePreviousRouteName({ commit }, payload: PreviousRouteType) {
    try {
      if (payload.path.includes(productListRoute)) {
        commit('updatePersisted', { productListRouteParams: payload })
      } else {
        commit('updatePersisted', { searchResultsRouteParams: payload })
      }
    } catch (e) {
      errorHandler(e)
    }
  },

  async getCareAreaCounters({ commit }) {
    try {
      const { data } = await apiClient.v3.productCountersV3UsingGet({
        headers: {
          accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
        },
      })
      commit('updateProductCounters', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  useFinderFilter({ dispatch }, payload: FinderFilterPayload) {
    logEvent(AnalyticEvent.USED_FILTER, {
      content: USED_FILTER_CONTENT[payload.filterGroup],
    })
    dispatch('moveToFilteredSearchResults', payload.filter)
  },

  moveToFilteredSearchResults({ state }, filter: FinderFilterType | string) {
    if (!state.searchResults) return
    const filterIds = getFilterKeysByName(filter)
    router.push({
      name: ROUTER_CONFIG.searchResults.name,
      query: {
        filter: urlEncodeFilters(filterIds),
      },
    })
  },

  async setUserListSortingType({ commit }, payload: UserSortingType) {
    commit('updateUserListSortingType', payload)
  },

  setScpUserStatus(
    { commit, state },
    scpStatus: API.DisplayUserProfileResponseDto['scpStatus'],
  ) {
    if (!state.user) {
      return
    }
    const user: API.DisplayUserProfileResponseDto = {
      ...state.user,
      scpStatus,
    }
    commit('updateUser', user)
  },

  async changeOriginScpCountry(_, payload: ChangeOriginScpCountryPayload) {
    try {
      await apiClient.user.changeOriginScpCountryUsingPatch(
        payload.userId,
        payload.country,
      )
    } catch (e) {
      await errorHandler(e)
    }
  },

  async setFirebaseConsentsPersisted({ commit, state }, payload: boolean) {
    if (!state.user?.id) {
      return
    }

    commit('updatePersisted', {
      firebaseConsents: {
        ...state.persisted?.firebaseConsents,
        [state.user.id]: payload,
      },
    })

    if (payload) {
      await startUserTracking(state.user)
    }
    commit('updatePersisted', {
      areConsentsChanged: true,
    })
  },

  setIsMultipleTutorialShowedPersisted({ commit, state }, payload: boolean) {
    if (!state.user?.id) {
      return
    }

    commit('updatePersisted', {
      isMultiProductsToMultiListsTutorialShowed: {
        ...state.persisted?.isMultiProductsToMultiListsTutorialShowed,
        [state.user.id]: payload,
      },
    })
  },

  setConsentsChanged({ commit }, payload: boolean) {
    try {
      commit('updatePersisted', {
        areConsentsChanged: payload,
      })
    } catch (e) {
      errorHandler(e)
    }
  },

  setIsListDetailsPinningTutorialShowedPersisted(
    { commit, state },
    payload: boolean,
  ) {
    if (!state.user?.id) {
      return
    }

    commit('updatePersisted', {
      isListDetailsPinningTutorialShowed: {
        ...state.persisted?.isListDetailsPinningTutorialShowed,
        [state.user.id]: payload,
      },
    })
  },

  setIsCuratedListDetailsPinningTutorialShowed(
    { commit, state },
    payload: boolean,
  ) {
    if (!state.user?.id) {
      return
    }

    commit('updatePersisted', {
      isSortingTutorialShowed: {
        ...state.persisted?.isCuratedListDetailsPinningTutorialShowed,
        [state.user.id]: payload,
      },
    })
  },

  setRedirectFromPath({ commit }, payload: PreviousRouteType) {
    try {
      commit('updatePersisted', {
        redirectFromPath: payload,
      })
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchRoleChangeInfo({ commit: _commit }, id: number) {
    try {
      const { data } = await apiClient.v3.getUserRoleChangeUsingGet(id, {
        headers: {
          accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
        },
      })

      return data
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchLastRoleChangeInfo({ commit }) {
    try {
      const { data } = await apiClient.v3.getNewestUserRoleChangeUsingGet({
        headers: {
          accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
        },
      })

      if (!data.read) {
        commit('updateModalVisibility', {
          roleChange: true,
        })
      }

      return data
    } catch (e) {
      if (e instanceof Response && e.status === 464) {
        return
      }

      errorHandler(e)
    }
  },

  async markRoleChangeAsRead({ commit: _commit }, id: number) {
    try {
      await apiClient.v3.markAsReadUsingPut(id, {
        headers: {
          accept: 'application/json', // TODO: DHOR-393 - Fix - application/app-v2.0+json not work
        },
      })
    } catch (e) {
      errorHandler(e)
    }
  },

  saveProductGridCardSizes({ commit }, cardSizes: Size) {
    try {
      commit('updatePersisted', { cardSizes })
    } catch (e) {
      errorHandler(e)
    }
  },
}
