import { useAppStore } from '@/store'
import useStaticData from '@/store/staticData/useStaticData'
import useUserHelper from '@/helpers/useUserHelper'
import { FinalizeOnboardingPayload } from '@/store/types'
import { computed, ref, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  CountryForListItem,
  OrganizationRoleListItem,
  LanguageListItem,
} from '@/store/staticData/types'
import { ROUTER_CONFIG } from '@/routes/router.config'
import { defineOptionsStore, OptionBuilder, isOption } from '@/utils'
import { OnboardingStepModel } from '../types'

export const useOnboarding = (
  currentTab: Ref<number>,
  setTab: (tab: number) => void,
) => {
  const store = useAppStore()
  const { countriesForList, organizationRolesForList, languagesForList } =
    useStaticData()

  const { locale, t } = useI18n()
  const router = useRouter()

  const { isSalesChannelPartner: isScpUser } = useUserHelper()

  const rolesOptions = computed(() =>
    defineOptionsStore<OrganizationRoleListItem>({
      values: () => organizationRolesForList.value,
      builder: OptionBuilder({
        getKey: (dto) => dto?.value ?? '',
        getLabel: (dto) => dto?.name,
      }),
    }),
  )

  const countriesOptions = computed(() =>
    defineOptionsStore<CountryForListItem>({
      values: () => countriesForList.value,
      builder: OptionBuilder({
        getKey: (dto) => dto?.id ?? '',
        getLabel: (dto) => dto?.name ?? '',
      }),
    }),
  )

  const languagesOptions = computed(() =>
    defineOptionsStore<{
      name: string
      shortcut: string
    }>({
      values: () => languagesForList.value,
      builder: OptionBuilder({
        getKey: (dto) => dto?.shortcut ?? '',
        getLabel: (dto) => dto?.name ?? '',
      }),
    }),
  )
  const currentUsedLanguage = languagesForList.value.find(
    (lang) => lang.shortcut.toLowerCase() === locale.value,
  )

  const currentRole = ref<OnboardingStepModel<OrganizationRoleListItem>>()
  const selectedRole = ref<OnboardingStepModel<OrganizationRoleListItem>>()

  const currentCountry = ref<OnboardingStepModel<CountryForListItem>>()
  const selectedCountry = ref<OnboardingStepModel<CountryForListItem>>()

  const currentLanguage = ref<OnboardingStepModel<LanguageListItem>>(
    languagesOptions.value.options.find(
      (el) => el.value.shortcut === currentUsedLanguage?.shortcut,
    ) ?? languagesOptions.value.options[0],
  )
  const selectedLanguage = ref<OnboardingStepModel<LanguageListItem>>()

  const showLanguageEditButton = computed(() => {
    return isOption(selectedLanguage.value) && currentTab.value !== 0
  })

  const showRoleEditButton = computed(() => {
    return isOption(selectedRole.value) && currentTab.value !== 1
  })

  const showCountryEditButton = computed(() => {
    const tab = isScpUser.value ? 1 : 2

    return isOption(selectedCountry.value) && currentTab.value !== tab
  })

  const onSelectedLanguageConfirm = async () => {
    selectedLanguage.value = currentLanguage.value
    const languageShortcut = isOption(selectedLanguage.value)
      ? selectedLanguage.value.value.shortcut
      : ''
    locale.value = languageShortcut.toLocaleLowerCase()
    await store.dispatch('setUserLanguage', {
      languageShortcut: languageShortcut,
    })
    await store.dispatch('staticData/fetchCountriesList')
    await store.dispatch('staticData/fetchOrganizationRolesList')
    setTab(1)
  }

  const onSelectedRoleConfirm = () => {
    selectedRole.value = currentRole.value

    setTab(2)
  }

  const onSelectedCountryConfirm = () => {
    selectedCountry.value = currentCountry.value

    isScpUser.value ? setTab(2) : setTab(3)
  }

  const onboardingSteps = computed(() => {
    const allSteps = [
      {
        header: t('common.selectYourLanguage'),
        placeholder: t('onboarding.searchLanguage'),
        isEditButtonVisible: showLanguageEditButton.value,
        summary: {
          isVisible:
            isOption(selectedLanguage.value) &&
            selectedLanguage.value.value &&
            currentTab.value !== 0,
          label: t('common.language'),
          value: isOption(selectedLanguage.value)
            ? selectedLanguage.value.value.name
            : undefined,
        },
        select: {
          value: currentLanguage.value,
          onChange: (val: OnboardingStepModel<LanguageListItem>) =>
            (currentLanguage.value = val),
          options: languagesOptions.value.options,
        },
        isSubmitDisabled: !isOption(currentLanguage.value),
        onSubmit: onSelectedLanguageConfirm,
        disabled: false,
      },
      {
        header: t('common.selectYourRole'),
        placeholder: t('onboarding.searchFunction'),
        isEditButtonVisible: showRoleEditButton.value,
        summary: {
          isVisible: isOption(selectedRole.value) && currentTab.value !== 1,
          label: t('common.role'),
          value: isOption(selectedRole.value)
            ? selectedRole.value.value.name
            : undefined,
        },
        select: {
          value: currentRole.value,
          onChange: (val: OnboardingStepModel<OrganizationRoleListItem>) =>
            (currentRole.value = val),
          options: rolesOptions.value.options,
        },
        isSubmitDisabled: !isOption(currentRole.value),
        onSubmit: onSelectedRoleConfirm,
        disabled: !isOption(selectedLanguage.value),
      },
      {
        header: t('common.selectYourCountry'),
        placeholder: t('onboarding.searchCountry'),
        isEditButtonVisible: showCountryEditButton.value,
        summary: {
          isVisible: !!(
            isOption(selectedCountry.value) &&
            selectedCountry.value.value.id &&
            currentTab.value !== 2 - Number(isScpUser.value)
          ),
          label: t('common.country'),
          value: isOption(selectedCountry.value)
            ? selectedCountry.value.value.name
            : undefined,
        },
        select: {
          value: currentCountry.value,
          onChange: (val: OnboardingStepModel<CountryForListItem>) =>
            (currentCountry.value = val),
          options: countriesOptions.value.options,
        },
        isSubmitDisabled: !isOption(currentCountry.value),
        onSubmit: onSelectedCountryConfirm,
        disabled: isScpUser.value
          ? !isOption(selectedLanguage.value)
          : !isOption(selectedRole.value),
      },
    ] as const

    if (!isScpUser.value) {
      return allSteps
    }

    return [allSteps[0], allSteps[2]] as const
  })

  const showFinishButton = computed(
    () =>
      !!(
        currentTab.value >= onboardingSteps.value.length &&
        isOption(selectedCountry.value) &&
        isOption(selectedLanguage.value) &&
        (isScpUser.value ? true : isOption(selectedRole.value))
      ),
  )

  const onFinishOnboarding = async () => {
    const payload: FinalizeOnboardingPayload = {
      organizationRole: isOption(selectedRole.value)
        ? selectedRole.value.value.value
        : undefined,
      languageShortcut: isOption(selectedLanguage.value)
        ? selectedLanguage.value.value.shortcut
        : '',
      countryId: isOption(selectedCountry.value)
        ? selectedCountry.value.value.id
        : undefined,
    }

    if (isScpUser.value) {
      await store.dispatch('users/assignToCountry', payload.countryId)

      router.push({ name: ROUTER_CONFIG.scpRegistrationWaitingRoom.name })

      return
    }
    await store.dispatch('finalizeOnboarding', payload)
  }

  return {
    onboardingSteps,
    showFinishButton,
    currentRole,
    onFinishOnboarding,
  }
}
